import clsx from "clsx";

import { FieldWrapper } from "./FieldWrapper";

export const InputField = (props) => {
  const {
    type = "text",
    label,
    className,
    placeholder,
    disabled,
    value,
    registration,
    onChange,
    error,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        className={clsx(
          "px-3 py-3 placeholder-black text-black relative  bg-red-200 rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full",
          className
        )}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        {...registration}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};
