import { queryClient } from "../lib/react-query";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";
import { Notifications } from "components/Notifications";
import { Spinner } from "components/elements";

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong 😢 </h2>
      <Link
        className="text-sm text-gray-400"
        onClick={() => (window.location = "mailto:jonahgeek@gmail.com")}
      >
        Contact Support{" "}
      </Link>
      <button
        className="rounded-full text-white p-4 bg-green-600 mt-4"
        onClick={() => {
          localStorage.clear();
          window.location.assign(window.location.origin);
        }}
      >
        Refresh
      </button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
            <Notifications />
            <Router>{children}</Router>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
