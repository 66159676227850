import React from "react";
import { Info, XCircle } from "react-feather";

export const Modal = (props) => {
  const { title, children, onClose } = props;

  return (
    <div
      className="relative z-10 transition ease-in-out delay-150"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <Info />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="flex flex-row">
                    <h3
                      className="text-lg leading-6 pt-2 font-medium text-gray-900"
                      id="modal-title"
                    >
                      {title}
                    </h3>
                    <div className="ml-auto">
                      <button
                        onClick={onClose}
                        className="flex flex-row items-center space-x-2 px-4 py-2 my-2 rounded-lg text-green-700 transition ease-in-out delay-150 hover:bg-green-600 hover:-translate-y-1 hover:scale-100 duration-300 hover:text-white"
                      >
                        <XCircle className="mx-auto h-8" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
