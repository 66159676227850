import { API_URL } from "../config";
import { useNotificationStore } from "../stores/notifications";
import Axios from "axios";
import { Modal } from "../components/elements";

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.code === "ERR_NETWORK")
      return (
        <Modal title="Network Error">
          <div className="text-sm">You've experienced a network error</div>
        </Modal>
      );
    const message = error.response?.data?.message || error.message;
    useNotificationStore.getState().addNotification({
      type: "error",
      title: "Error",
      message,
    });

    return Promise.reject(error);
  }
);
