import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotificationStore } from "stores/notifications";
import PropTypes from "prop-types";
import { useMutation } from "react-query";

export const subscribeToEvent = ({ data }) => {
  return axios.post(`/subscribers`, data);
};

subscribeToEvent.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
};

export const useSubscribeToEvent = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newSub) => {
      await queryClient.cancelQueries("subs");

      const previousSubs = queryClient.getQueryData("subs");

      queryClient.setQueryData("subs", [...(previousSubs || []), newSub.data]);

      return { previousSubs };
    },
    onError: (_, __, context) => {
      if (context?.previousSubs) {
        queryClient.setQueryData("subs", context.previousSubs);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("subs");
    },
    ...config,
    mutationFn: subscribeToEvent,
  });
};
