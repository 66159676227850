import { MainLayout } from "components/layout";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useConfirmSubscription } from "../api/confirmSubscription";

export const ConfirmEvent = () => {
  const { token } = useParams();
  const confirmEventQuery = useConfirmSubscription({ token });

  useEffect(() => {
    if (confirmEventQuery.isSuccess) {
      Swal.fire(
        "Awesome!",
        "We have confirmed your subscription to the event",
        "success"
      );
    }
  }, [confirmEventQuery.isSuccess]);

  return (
    <MainLayout>
      <div className="container">
        <div className="row">
          <div className="py-40 text-white">
            <h1 className="text-4xl text-center font-bold">
              {confirmEventQuery.isSuccess
                ? "Stay Awesome & Keep Your City Clean!"
                : "Confirming your subscription"}
            </h1>
            <h1 className="text-3xl text-center">
              Doing Nounish things for the community
            </h1>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
