import { axios } from "lib/axios";
import { useQuery } from "react-query";
import PropTypes from "prop-types";

export const confirmSubscription = ({ token }) => {
  return axios.patch(`/subscribers/confirm/${token}`);
};

confirmSubscription.propTypes = {
  data: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

export const useConfirmSubscription = ({ token, config }) => {
  return useQuery({
    ...config,
    queryKey: ["sub", token],
    queryFn: () => confirmSubscription({ token }),
  });
};
