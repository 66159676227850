/** @format */

import React from "react";
import Noun444 from "img/noun-444.png";
import Noun445 from "img/noun-445.png";
import Noun446 from "img/noun-446.png";
import { MainLayout } from "components/layout";
import * as z from "zod";
import { useSubscribeToEvent } from "../api/subscribeToEvent";
import { Form, InputField } from "components/form";
import { Spinner } from "flowbite-react";
import { Check } from "react-feather";

const schema = z.object({
  name: z.string().min(1, "Required"),
  email: z.string().min(1, "Required"),
  phone: z.string().min(1, "Required"),
});

export const Landing = () => {
  const [showModal, setShowModal] = React.useState(false);

  const subscribeToEventMutation = useSubscribeToEvent();

  // timeout to close modal
  const timeout = () => {
    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  };

  return (
    <>
      <MainLayout>
        <div
          className="text-white uppercase text-center text-7xl md:text-largest font-bold pt-5 md:pt-20 pb-6"
          // style={{
          // 	fontSize: "10rem",
          // }}>
        >
          Bulungi Bwansi
        </div>
        <div
          className="text-white text-center text-6xl md:text-semi-large font-bold"
          // style={{
          // 	fontSize: "7rem",
          // }}
        >
          Keep your city clean
        </div>
        <div className="my-10 flex flex-row justify-center">
          <button
            onClick={() => setShowModal(true)}
            className="bg-white p-10 rounded-full text-2xl font-bold"
          >
            Register for Event
          </button>
          {/* Modal  */}

          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-11/12 md:w-5/12 my-6 md:mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">
                        Register for Event
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}

                    {subscribeToEventMutation.isSuccess ? (
                      <div className="h-80 flex flex-col items-center space-y-4 justify-center">
                        <div className="flex flex-row items-center bg-green-100 justify-center rounded-full p-4">
                          <Check className="text-green-400" />
                        </div>
                        <span className="text-red-700">
                          Please check your email and confirm your spot.
                        </span>
                      </div>
                    ) : (
                      <Form
                        id="subscribe-to-event"
                        onSubmit={async (values) => {
                          console.log(values);
                          await subscribeToEventMutation.mutateAsync({
                            data: values,
                          });
                        }}
                        schema={schema}
                      >
                        {({ register, formState }) =>
                          subscribeToEventMutation.isLoading ? (
                            <div className="flex items-center justify-center h-80 flex flex-row space-x-2">
                              <span>Just a sec ..</span>
                              <Spinner />
                            </div>
                          ) : (
                            <>
                              <div className="relative p-6 flex-auto">
                                <div className="flex gap-2 flex-col">
                                  <InputField
                                    label="Name"
                                    registration={register("name")}
                                    error={formState.errors["name"]}
                                  />

                                  <InputField
                                    label="Email"
                                    registration={register("email")}
                                    error={formState.errors["email"]}
                                  />

                                  <InputField
                                    label="Phone (256 7xx xxx xxx)"
                                    registration={register("phone")}
                                    error={formState.errors["phone"]}
                                  />
                                </div>
                              </div>
                              {/*footer*/}
                              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                  className="text-red-500 background-transparent font-bold  px-6 py-2 text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => setShowModal(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="bg-red-500 text-white active:bg-red-600 font-bold  text-xl px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="submit"
                                >
                                  Register
                                </button>
                              </div>
                            </>
                          )
                        }
                      </Form>
                    )}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>

        <div
          className="text-black text-center font-bold mb-10"
          style={{
            fontSize: "5rem",
          }}
        >
          September 24th, 2022 <br />
          9am - 12pm
        </div>
        <div
          className="text-white text-center mx-2 md:mx-0 font-bold"
          style={{
            fontSize: "4rem",
          }}
        >
          Kikaya-Kikulu-Ddungu (LC1)
        </div>
        <div
          className="text-white text-center mx-2 md:mx-0 font-bold"
          style={{
            fontSize: "4rem",
          }}
        >
          Kisaasi, Kampala, Uganda
        </div>
        <div className="grid justify-center md:grid-cols-3 gap-4 mt-10 bg-red-400">
          <img src={Noun444} alt="Noun 444" />
          <img src={Noun445} alt="Noun 445" />
          <img src={Noun446} alt="Noun 446" />
        </div>
      </MainLayout>
    </>
  );
};
