import { ConfirmEvent, Landing } from "features/landing";
import { NotFound } from "features/notfound";

export const publicRoutes = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/attend/confirm/:token",
    element: <ConfirmEvent />,
  },

  {
    path: "*",
    element: <NotFound />,
  },
];
